import { createWebHistory, createRouter } from "vue-router";

import carta from "@/pages/carta.vue";
const routes = [

  {
    path: "/:id?",
    name: "carta",
    component: carta,
  },
  
  {
    path: '/:pathMatch(.*)*',
    component: carta,
    // https://stackoverflow.com/questions/68504803/how-can-i-make-a-catch-all-other-route-in-vue-router-also-catch-the-url-when-p
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;