<template>
  <div class="header" v-show= " instagram || facebook || whatsapp || web || tiktok || tripadvisor || google">
    <a v-show="instagram" :href="'https://www.instagram.com/' + instagram" target="_blank">
      <i class="fab fa-instagram iconInstagram"></i>
    </a>
    <a v-show="facebook" :href="facebook" target="_blank">
      <i class="fa-brands fa-facebook iconFacebook"></i>
    </a>
    <a v-show="whatsapp" :href="'https://api.whatsapp.com/send?phone=34' + whatsapp" target="_blank">
      <i class="fa-brands fa-whatsapp iconWhatsapp"></i>
    </a>

    <a v-show="tiktok"  :href="'https://www.tiktok.com/@' + tiktok" target="_blank">
      <i class="fa-brands fa-tiktok iconTiktok"></i>
    </a>

    <a v-show="tripadvisor" :href="tripadvisor" target="_blank">
      <img class="img2" src="../assets/images/tripadvisor-icon.png" alt="Tripadvisor">
    </a>
    
    <a v-show="google" :href="google" target="_blank">
      <i class="fa-brands fa-google iconGoogle"></i>
    </a>

    <a v-show="web" :href="web" target="_blank">
      <i class="fa-solid fa-globe iconWeb"></i>
    </a>
  </div>

  <div class="header" v-show= "hashtag">
    <h1 class="hashtag">  <img class="img" src="../assets/images/hashtag.png">{{ hashtag }}</h1> 
  </div>

  <div class="idioma">
    <img v-show="pdfLinks.espanol && !cartaEspanol" class="imgBandera" :class="{'selected': cartaEspanol}" src="../assets/images/banderaEspaña.png" @click="cartaEspanol ? null : cambiarIdioma('espanol')" alt="Español">
    <img v-show="pdfLinks.ingles && !cartaIngles" class="imgBandera" :class="{'selected': cartaIngles}" src="../assets/images/united-kingdom.png"  @click="cartaIngles ? null : cambiarIdioma('ingles')" alt="Inglés">
  </div>

  <div v-show="error" class="error-message">
    {{ error }}
  </div>

  <div v-show="isLoading" class="loading">
    Cargando carta...
  </div>
    
  <div class="pdf-container" v-show="!isLoading && !error && (cartaEspanol || cartaIngles)">
    <canvas v-for="page in numOfPages" :key="page" :id="'canvas-' + page"></canvas>
  </div>
</template>

<script>
import axios from 'axios';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import confetti from 'canvas-confetti';

GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@4.0.379/build/pdf.worker.mjs";

export default {
name: 'carta',
  data() {
    return {
      numOfPages: 0,
      cartaEspanol: true,
      cartaIngles: false,
      isLoading: false,
      error: null,
      pdfLinks: {
        espanol: '',
        ingles: ''
      },
      instagram: '',
      facebook: '',
      whatsapp: '',
      web: '',
      tiktok: '',
      tripadvisor: '',
      google: '',
      hashtag: ''
    };
  },

  mounted() {
  let id = this.$route.params.id;

  Promise.all([
    this.obtenerDatosRedes(id),
    this.obtenerDatosCarta(id)
  ])
  .then(() => {
    this.mostrarPDF();
  })
  .catch(error => {
    console.error("Error al realizar las solicitudes:", error);
  });
},


  methods: {
    async obtenerDatosRedes(id) {
    try {
      const response = await axios.get(`https://mizatec.es:8081/api/datosRedesSociales/${id}`);
      if (response.data && response.data.length > 0) {
        const datosRedes = response.data[0];
        this.instagram = datosRedes.instagram || '';
        this.whatsapp = datosRedes.whatsapp || '';
        this.facebook = datosRedes.facebook || '';
        this.web = datosRedes.web || '';
        this.tiktok = datosRedes.tiktok || '';
        this.tripadvisor = datosRedes.tripadvisor || '';
        this.google = datosRedes.google || '';
        this.hashtag = datosRedes.hashtag || '';
      } else {
        // Manejar el caso en que la respuesta no contenga los datos esperados
        console.error("La respuesta de la API no contiene datos de redes sociales.");
      }
    } catch (error) {
      console.error("Error obteniendo los datos de redes sociales:", error);
      // Establecer valores predeterminados o manejar el error como sea apropiado
    }
  },

    async obtenerDatosCarta(id) {
      try {
        const response = await axios.get(`https://mizatec.es:8081/api/obtenerDatosCarta/${id}`);
        this.pdfLinks.espanol = response.data[0].url_carta_es;
        this.pdfLinks.ingles = response.data[0].url_carta_en;
      } catch (error) {
        console.error("Error obteniendo los datos:", error);
      }
    },

    async mostrarPDF() {
      this.isLoading = true;
      this.error = null;
      try {
        let url = this.pdfLinks.espanol;

        if (!this.pdfLinks.espanol && this.pdfLinks.ingles) {
          url = this.pdfLinks.ingles;
          this.cartaEspanol = false;
          this.cartaIngles = true;
        }

        const pdfDoc = await getDocument({url}).promise;
        this.numOfPages = pdfDoc.numPages;
        this.renderPDFPages(pdfDoc);
      } catch (error) {
        this.error = "Sube tu archivo PDF en mizatec.es";
      } finally {
        this.isLoading = false;
        this.lanzarConfeti();
      }
    },


    async renderPDFPages(pdfDoc) {
      const scale = 2.0; 
      for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {

        const page = await pdfDoc.getPage(pageNum);
        const viewport = page.getViewport({ scale });
        const canvas = document.getElementById('canvas-' + pageNum);
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        await page.render(renderContext).promise;
      }
    },

    lanzarConfeti() {
      confetti({
        particleCount: 120,
        spread: 70,
        origin: { y: 0.6 }
      });
    },

    cambiarIdioma(language) {
      if (language === 'espanol' && this.isCartaEspAvailable) {
        this.cartaEspanol = true;
        this.cartaIngles = false;
      }
      else if (language === 'ingles' && this.isCartaIngAvailable) {
        this.cartaEspanol = false;
        this.cartaIngles = true;
      }
      this.mostrarPDF();
    },

  },
};
</script>


<style scoped>

canvas {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  /* Otros estilos que desees añadir */
}
.header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px; /* Puedes ajustar esto según tus necesidades */
    background-color: #f5f5f5; /* Un fondo claro, ajusta al color que desees */
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); /* Una pequeña sombra para dar profundidad */
    margin: 6px;
    border-radius: 12px;

}

.header a {
    margin: 0 10px; /* Espacio entre los íconos */
    text-decoration: none; /* Eliminar el subrayado de los enlaces */
    color: inherit; /* El color del ícono heredará el color del padre (en este caso .header) */
}

.header a:hover i {
    transform: scale(1.1); /* Aumenta el tamaño del ícono un poco al pasar el ratón por encima */
}
.idioma{
  display: flex;
  justify-content: flex-end;
  position: fixed;       
  bottom: 10px;          
  right: 10px;           
  z-index: 1000;
}

.iconGoogle{
  color: #4285F4;
  font-size: 35px;

}

.iconInstagram {
    color: #E1306C;
    font-size: 38px;
}

.iconWhatsapp {
    color: #25D366;
    font-size: 43px;
}

.iconFacebook {
    color: #1877F2;
    font-size: 38px;
}

.iconTiktok{
  font-size: 35px;
}
.img{
    height: 30px;
    width: 30px;
    margin-right: 2px;
}
.img2{
    height: 40px;
    width: 40px;
}
.iconWeb{
    color: #000000;
    font-size: 35px;
}

.imgBandera{
  width: 50px;
  height: 50px;
  margin: 12px;
}


.selected {
    border: 3px solid black; /* Ajusta el color según tu preferencia */
    border-radius: 40px; /* Para bordes ligeramente redondeados */
    box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5); /* Un suave resplandor alrededor */
}
.loading {
  text-align: center;
  padding: 20px;
  font-size: 22px;
  font-family: 'Exo', sans-serif;
  font-weight: 500;

}
.hashtag{
  font-family: 'Exo', sans-serif;
    font-size: 24px;
    display: flex;
    align-items: stretch;

}

.error-message {
  text-align: center;
  color: red;
  padding: 20px;
  font-size: 18px;
  font-family: 'Exo', sans-serif;
}

</style>